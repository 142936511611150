import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-cb724dc8"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "pie3d"
};
import { ref } from 'vue';
import echart3DPie from '../../components/echart3DPie/echart3DPie.vue'; // 传入数据生成 option

export default {
  __name: 'echart-expend',

  setup(__props) {
    const optionsData = ref([{
      name: '运营支出',
      value: 345135,
      itemStyle: {
        opacity: 1,
        // 图形的透明底 0-1
        color: '#30fdff'
      }
    }, {
      name: '人员经费',
      value: 244435,
      itemStyle: {
        opacity: 1,
        color: '#30ffb3'
      }
    }, {
      name: '扶持培训',
      value: 145735,
      itemStyle: {
        opacity: 1,
        color: '#3098ff'
      }
    }, {
      name: '产权保护',
      value: 782435,
      itemStyle: {
        opacity: 1,
        color: '#c1ff30'
      }
    }]);
    const option = ref({
      legend: {
        show: true
      }
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(echart3DPie, {
        optionsData: optionsData.value,
        option: option.value
      }, null, 8, ["optionsData", "option"])]);
    };
  }

};