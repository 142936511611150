import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-40c2f677"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "mapWrapper"
};
import axios from 'axios';
import { ref, onMounted } from 'vue';
import * as echarts from 'echarts';
export default {
  __name: 'echart-map',

  setup(__props) {
    const mapChartRef = ref();
    const initChinaMap = ref();
    const initAdCode = ref(100000); // 默认中国地图的编码

    const currentMapName = ref('中国'); // 默认地图的名称

    const mapList = ref([]); // 当前展示的省市区所有数据(全面)

    const mapListall = ref([]); // 当前展示的省市区所有数据

    const historyInfo = ref([]); // 记录历史点击的省市区，用于返回查询

    const dataList = ref(); // 获取地图数据

    const getMapData = async code => {
      const data = await axios.get(`https://geo.datav.aliyun.com/areas_v3/bound/${code}_full.json`).then(res => {
        mapList.value = [];
        mapListall.value = [];

        if (res.status === 200) {
          // 获取当前选中的省市区的所有名称和编码
          res.data.features.forEach(item => {
            item.properties.conNum = Math.ceil(Math.random() * 3000);
            mapList.value.push(item.properties);
            mapListall.value.push(item);
            return item;
          });
          renderChart(currentMapName.value, res.data);
        }
      }).catch(() => {});
      return data;
    }; // 渲染地图


    const renderChart = (mapName, mapData) => {
      // 注册: registerMap('地图名称', 地图数据）, 地图名称须和option里面的map一致
      echarts.registerMap(mapName, mapData); // 地图配置项

      const option = {
        series: [{
          type: 'map',
          map: mapName,
          // 地图名称
          // data: [],
          zoom: '1.2',
          // 当前视角的缩放比例
          roam: false,
          // 是否开启缩放和平移
          // 设置鼠标移上去高亮的样式
          emphasis: {
            itemStyle: {
              borderColor: '#2d81be',
              areaColor: '#2d81be',
              borderType: 'solid'
            }
          },
          label: {
            normal: {
              show: true,
              color: 'rgb(249, 249, 249)',
              // 省份标签字体颜色
              formatter: p => {
                switch (p.name) {
                  case '内蒙古自治区':
                    p.name = '内蒙古';
                    break;

                  case '西藏自治区':
                    p.name = '西藏';
                    break;

                  case '新疆维吾尔自治区':
                    p.name = '新疆';
                    break;

                  case '宁夏回族自治区':
                    p.name = '宁夏';
                    break;

                  case '广西壮族自治区':
                    p.name = '广西';
                    break;

                  case '香港特别行政区':
                    p.name = '香港';
                    break;

                  case '澳门特别行政区':
                    p.name = '澳门';
                    break;
                }

                return p.name;
              }
            },
            emphasis: {
              show: true,
              color: '#f75a00' // 当鼠标悬浮时省份名称字体的颜色

            }
          },
          itemStyle: {
            normal: {
              // areaColor: '#24CFF4', // 板块的背景色
              areaColor: {
                type: 'radial',
                x: 0.5,
                y: 0.5,
                r: 0.9,
                colorStops: [{
                  offset: 0,
                  color: 'rgba(17,217,245,0.5)' // 0% 处的颜色

                }, {
                  offset: 1,
                  color: 'rgba(10,209,231,0.2)' // 100% 处的颜色

                }],
                globalCoord: false // 缺省为 false

              },
              borderColor: '#53D9FF',
              borderWidth: 1.3,
              shadowBlur: 15,
              shadowColor: 'rgb(58,115,192)',
              shadowOffsetX: 7,
              shadowOffsetY: 6
            },
            // 鼠标再板块悬停板块的
            emphasis: {
              areaColor: '#8dd7fc',
              borderWidth: 1.6,
              shadowBlur: 25
            }
          },
          left: '10%',
          data: [{
            name: '北京市',
            value: 290
          }, {
            name: '天津市',
            value: 320
          }, {
            name: '河北省',
            value: 240
          }, {
            name: '山西省',
            value: 520
          }, {
            name: '内蒙古自治区',
            value: 120
          }, {
            name: '辽宁省',
            value: 210
          }, {
            name: '吉林省',
            value: 620
          }, {
            name: '黑龙江省',
            value: 20
          }, {
            name: '上海市',
            value: 220
          }, {
            name: '江苏省',
            value: 820
          }, {
            name: '浙江省',
            value: 320
          }, {
            name: '安徽省',
            value: 520
          }, {
            name: '福建省',
            value: 120
          }, {
            name: '江西省',
            value: 620
          }, {
            name: '山东省',
            value: 920
          }, {
            name: '河南省',
            value: 220
          }, {
            name: '湖北省',
            value: 720
          }, {
            name: '湖南省',
            value: 210
          }, {
            name: '广东省',
            value: 20
          }, {
            name: '广西壮族自治区',
            value: 20
          }, {
            name: '海南省',
            value: 240
          }, {
            name: '重庆市',
            value: 20
          }, {
            name: '四川省',
            value: 240
          }, {
            name: '贵州省',
            value: 420
          }, {
            name: '云南省',
            value: 320
          }, {
            name: '西藏自治区',
            value: 20
          }, {
            name: '陕西省',
            value: 240
          }, {
            name: '甘肃省',
            value: 920
          }, {
            name: '青海省',
            value: 720
          }, {
            name: '宁夏回族自治区',
            value: 120
          }, {
            name: '新疆维吾尔自治区',
            value: 420
          }, {
            name: '台湾省',
            value: 230
          }, {
            name: '香港特别行政区',
            value: 420
          }, {
            name: '澳门特别行政区',
            value: 210
          }]
        }],
        // 提示浮窗样式
        tooltip: {
          show: true,
          trigger: 'item',
          alwaysShowContent: false,
          backgroundColor: '#0C121C',
          // 浮窗背景颜色
          borderColor: 'rgba(0, 0, 0, 0.16);',
          hideDelay: 100,
          triggerOn: 'mousemove',
          enterable: true,
          textStyle: {
            color: '#DADADA'
          },
          showDelay: 100,
          formatter: function (p) {
            const txtCon = "<div style='text-align:left'>" + p.name + ':<br />销售额：' + p.value + '万</div>';
            return txtCon;
          }
        },
        visualMap: {
          min: 0,
          max: 1000,
          inRange: {
            color: ['rgb(36,207,244)', 'rgb(30,98,172)'] // 设置颜色范围，浅色到深色

          },
          calculable: true,
          show: true
        }
      }; // 渲染

      initChinaMap.value.setOption(option); // 防止多次触发click事件，重要！！！

      initChinaMap.value.off('click'); // 下钻

      initChinaMap.value.on('click', params => {
        const activeItem = mapList.value.find(item => item.name === params.name); // 判断有子级的时候才可以下钻

        if (activeItem && activeItem.adcode && activeItem.childrenNum) {
          historyInfo.value.push(activeItem);
          currentMapName.value = params.name;
          getMapData(activeItem.adcode);
        } else {
          historyInfo.value.push(activeItem);
          currentMapName.value = params.name; // 没有子集就把当前的区展示到页面上

          const lastMapListIndex = ref();
          mapListall.value.map((item, index) => {
            if (params.name === item.properties.name) {
              lastMapListIndex.value = index;
            }
          });
          const lastMapList = {
            type: 'FeatureCollection',
            features: Array(mapListall.value[lastMapListIndex.value])
          };
          renderChart(params.name, lastMapList);
        }
      });
    };

    onMounted(() => {
      dataList.value = [{
        name: '北京市',
        value: 290
      }, {
        name: '天津市',
        value: 320
      }, {
        name: '河北省',
        value: 240
      }, {
        name: '山西省',
        value: 520
      }, {
        name: '内蒙古自治区',
        value: 120
      }, {
        name: '辽宁省',
        value: 210
      }, {
        name: '吉林省',
        value: 620
      }, {
        name: '黑龙江省',
        value: 20
      }, {
        name: '上海市',
        value: 220
      }, {
        name: '江苏省',
        value: 820
      }, {
        name: '浙江省',
        value: 320
      }, {
        name: '安徽省',
        value: 520
      }, {
        name: '福建省',
        value: 120
      }, {
        name: '江西省',
        value: 620
      }, {
        name: '山东省',
        value: 920
      }, {
        name: '河南省',
        value: 220
      }, {
        name: '湖北省',
        value: 720
      }, {
        name: '湖南省',
        value: 210
      }, {
        name: '广东省',
        value: 20
      }, {
        name: '广西壮族自治区',
        value: 20
      }, {
        name: '海南省',
        value: 240
      }, {
        name: '重庆市',
        value: 20
      }, {
        name: '四川省',
        value: 240
      }, {
        name: '贵州省',
        value: 420
      }, {
        name: '云南省',
        value: 320
      }, {
        name: '西藏自治区',
        value: 20
      }, {
        name: '陕西省',
        value: 240
      }, {
        name: '甘肃省',
        value: 920
      }, {
        name: '青海省',
        value: 720
      }, {
        name: '宁夏回族自治区',
        value: 120
      }, {
        name: '新疆维吾尔自治区',
        value: 420
      }, {
        name: '台湾省',
        value: 230
      }, {
        name: '香港特别行政区',
        value: 420
      }, {
        name: '澳门特别行政区',
        value: 210
      }];
      initChinaMap.value = echarts.init(mapChartRef.value);
      getMapData(initAdCode.value);
    }); // 返回上一级

    const goBack = () => {
      const lastItem = historyInfo.value.pop();

      if (lastItem && lastItem.parent && lastItem.parent.adcode) {
        getMapData(lastItem.parent.adcode);
      } else {
        getMapData(lastItem.parent.adcode);
      }
    };

    return (_ctx, _cache) => {
      const _component_a_button = _resolveComponent("a-button");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_a_button, {
        type: "primary",
        onClick: goBack
      }, {
        default: _withCtx(() => [_createTextVNode("返回上级")]),
        _: 1
      }), _createElementVNode("div", {
        ref_key: "mapChartRef",
        ref: mapChartRef,
        style: {
          "height": "120%",
          "width": "100%"
        }
      }, null, 512)]);
    };
  }

};