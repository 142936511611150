import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-bd0fffc0"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "echartsall"
};
import baseEchart from '../../components/baseEchart/index.vue';
export default {
  __name: 'echart-income',

  setup(__props) {
    const options = {
      legend: {
        data: ['服务', '商品'],
        textStyle: {
          //   color: {
          //     服务: '#ffffff', // 图例文字颜色为红色
          //     商品: 'blue' // 图例文字颜色为蓝色
          //   }
          color: 'auto',
          rich: {
            b: {
              color: '#FF8D1A',
              fontSize: '16px',
              // 确保鼠标悬停时有提示框样式
              hoverStill: true
            },
            c: {
              color: '#39C9FF',
              fontSize: '16px',
              // 确保鼠标悬停时有提示框样式
              hoverStill: true
            }
          }
        }
      },
      // 修改图例颜色
      formatter: function (name) {
        if (name === '服务') {
          return '{b|' + name + '}';
        }

        if (name === '商品') {
          return '{c|' + name + '}';
        }
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        },
        // 悬停时有提示内容  解决了formatter修改图例后提示内容消失的问题
        formatter: function (params) {
          // 第一个小色块
          if (params[0]) {
            var servecolorBlock = '<div style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:' + params[0].borderColor + ';"></div>';
          } // 第二个小色块


          if (params[1]) {
            var shopcolorBlock = '<div style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:' + params[1].borderColor + ';"></div>';
          }

          if (params[0] && params[1]) {
            return params[0].axisValueLabel + '<br/>' + servecolorBlock + '&nbsp;' + params[0].seriesName + ' : &nbsp;&nbsp;&nbsp;&nbsp;' + params[0].data + '<br/>' + shopcolorBlock + '&nbsp;' + params[1].seriesName + ' : &nbsp;&nbsp;&nbsp;&nbsp;' + params[1].value;
          } else if (params[0]) {
            return params[0].axisValueLabel + '<br/>' + servecolorBlock + '&nbsp;' + params[0].seriesName + ' : &nbsp;&nbsp;&nbsp;&nbsp;' + params[0].data;
          }
        }
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: [{
        splitLine: {
          show: true,
          lineStyle: {
            color: 'rgba(168, 168, 168, 0.2)'
          }
        },
        axisLabel: {
          color: '#fff'
        },
        type: 'value',
        show: true
      }],
      yAxis: [{
        type: 'category',
        // inverse: true, // 排序
        splitLine: {
          show: false
        },
        axisLine: {
          // y轴
          show: true
        },
        axisTick: {
          show: false
        },
        data: ['鱼病防治', '翻缸造景', '其他服务', '搬移安装', '鱼缸检修', '包年服务', '深度清洗', '常规清洗'],
        axisLabel: {
          interval: 0,
          margin: 80,
          textStyle: {
            color: '#fff',
            align: 'left',
            fontWeight: 'normal',
            fontSize: '12'
          }
        }
      }],
      series: [{
        name: '服务',
        type: 'bar',
        barWidth: 10,
        // 柱子宽度
        label: {
          // 柱子上方的数值
          show: true,
          position: 'right',
          // 位置
          color: '#39FFD1',
          fontSize: 14,
          distance: 5 // 距离

        },
        itemStyle: {
          color: '#FF8D1A50',
          borderWidth: 1,
          borderColor: '#FF8D1A'
        },
        data: [41, 164, 91, 135, 102, 144, 122, 334]
      }, {
        name: '商品',
        type: 'bar',
        barWidth: 10,
        // 柱子宽度
        label: {
          // 柱子上方的数值
          show: true,
          position: 'right',
          // 位置
          color: '#39FFD1',
          fontSize: 14,
          distance: 5 // 距离

        },
        itemStyle: {
          color: '#39C9FF50',
          borderWidth: 1,
          borderColor: '#39C9FF'
        },
        data: [81, 104, 121, 145, 152, 164, 23, 45]
      }]
    };
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(baseEchart, {
        option: options,
        width: "100%",
        height: "600px"
      })]);
    };
  }

};